import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { retry, map, catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import * as pako from 'pako';
import { LogkanalService } from './logkanal.service';


// @Injectable({ providedIn: 'root' })
@Injectable()
export class SpectraldataService {
  logischerKanal: number = 0;

  private httpOptions = {
    headers: new HttpHeaders({
    })
  };
  constructor(private logKanalService: LogkanalService, private http: HttpClient) {
    this.logischerKanal = this.logKanalService.getLogischerKanal();
  }

  getSpectrums(rel: Number, ts: Number, limit: Number): any {
    return this.http.get( `${environment.apiUrl}` + '?rel=' + rel + '&ts=' + ts + '&l=' + limit + '&lkid='+this.logischerKanal, this.httpOptions )
    .pipe(
      map( function(result: any) { // TODO: Interface
        const spectrums = [];
        result.list.forEach(singleSpectrum => {
          // console.log('elem', singleSpectrum);
          const spectrumData = [];
          let freq = 0;
          let spectrum = {};
          // console.log(result);
          const inflate = from8to32(pako.inflate(atob(singleSpectrum.Daten)));
          // console.log(inflate);
          inflate.forEach (function (noiseLevel) {
            // console.log(noiseLevel);
            const item = {
              'freq': freq.toFixed(2),
              'noiseLevel': noiseLevel.toFixed(2)
            };
            freq = freq + singleSpectrum.Linienbreite;
            spectrumData.push(item);
          });
          spectrum = {
            time: new Date(singleSpectrum.Zeitstempel * 1000),
            data: spectrumData
          }
          // console.log(spectrum);
          spectrums.push(spectrum);
        });
        return spectrums;
      } )
    );
    function from8to32 (src) {
      const dst = new ArrayBuffer(src.byteLength);
      new Uint8Array(dst).set(new Uint8Array(src));
      const view = new DataView(dst);
      const float32 = new Float32Array(Math.floor(src.byteLength / 4));
      for(let i = 0; i < float32.length; i++) {
          float32[i] = view.getFloat32(i * 4);
      }
      return float32;
    }
  }
  getSpectrumsArray(rel: Number, ts: Number, limit: Number): any {
    return this.http.get( `${environment.apiUrl}` + '?rel=' + rel + '&ts=' + ts + '&l=' + limit + '&lkid='+this.logischerKanal, this.httpOptions )
    .pipe(
      map( function(result: any) { // TODO: Interface
        let freq = this.minFreq, i = 0;
        const anzahlLinien = result.list[0].AnzahlLinien;
        const linienBreite = result.list[0].Linienbreite;
        const spectrumsFreq = [];
        const spectrumsTime = [];
        const spectrumsArray = [];
        result.list.forEach(singleSpectrum => {
          // console.log('elem', singleSpectrum);
          const spectrum = [];
          // let spectrum = {};
          // console.log(result);
          const inflate = from8to32(pako.inflate(atob(singleSpectrum.Daten)));
          // console.log(inflate);
          inflate.forEach (function (noiseLevel) {
            spectrum.push(noiseLevel.toFixed(2));
          });
          spectrumsArray.push(spectrum);
          spectrumsTime.push( new Date(singleSpectrum.Zeitstempel * 1000) );
        });
        for ( ; i < anzahlLinien; i++ ) {
          freq += linienBreite * i;
          spectrumsFreq.push(freq);
        }
        // console.log(spectrumsFreq);
        return {
          dates: spectrumsTime,
          freq: spectrumsFreq,
          noise: spectrumsArray[0].map((x,i) => spectrumsArray.map(x => x[i]))
        };
      } )
    );
    function from8to32 (src) {
      const dst = new ArrayBuffer(src.byteLength);
      new Uint8Array(dst).set(new Uint8Array(src));
      const view = new DataView(dst);
      const float32 = new Float32Array(Math.floor(src.byteLength / 4));
      for(let i = 0; i < float32.length; i++) {
          float32[i] = view.getFloat32(i * 4);
      }
      return float32;
    }
  }
}
