import { Spectrum, SpectrumData, SpectrumResult } from './../_models/spectrum';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { LOAD, ADD, EDIT, REMOVE, SpectrumStore } from 'src/app/_stores/spectrum.store';
import { environment } from 'src/environments/environment';
import * as pako from 'pako';
import { LogkanalService } from './logkanal.service';

// Doku: Angular Handbuch S.509

@Injectable({
  providedIn: 'root'
})
export class SpectrumService {
  spectrums$: Observable<Spectrum[]>;
  spectrumsChanged = new BehaviorSubject({});
  logischerKanal:number = 0;
  constructor (
    private logKanalService: LogkanalService,
    private http: HttpClient,
    private spectrumStore: SpectrumStore ) {
      this.spectrums$ = spectrumStore.items$;
      this.logischerKanal = this.logKanalService.getLogischerKanal();
  }

  findSpectrums(time = new Date, order = 'ASC') {
    const searchParams = new HttpParams()
      .append('ts', Math.round(time.getTime() / 1000).toString())
      .append('_order', order)
      .append('lkid', this.logischerKanal.toString());
    this.http.get(`${environment.apiUrl}`, {params: searchParams}).pipe(
      tap((spectrumsRaw: SpectrumResult) => {
        const spectrums = [];
        spectrumsRaw.list.forEach((singleSpectrum) => {
          const spectrumData = [];
          let freq = 0;
          let spectrum = new Spectrum;
          // console.log(singleSpectrum);
          const inflate = this.from8to32(pako.inflate(atob(singleSpectrum.Daten)));
          inflate.forEach (function (amplitude, idxNo) {
            // console.log(noiseLevel);
            const item = {
              'frequency': Math.round(freq * 100) / 100,
              'amplitude': Math.round(amplitude * 100) / 100
              // 'frequency': freq.toFixed(2),
              // 'amplitude': amplitude.toFixed(2)
            };
            freq = freq + singleSpectrum.Linienbreite;
            spectrumData.push(item);
          });
          spectrum = {
            Id: singleSpectrum.Id,
            Tbllogischerkanal_id: singleSpectrum.Tbllogischerkanal_id,
            Zeitstempel: new Date(singleSpectrum.Zeitstempel * 1000),
            AnzahlLinien: singleSpectrum.AnzahlLinien,
            Linienbreite: singleSpectrum.Linienbreite,
            Daten: spectrumData,
            Ldz: singleSpectrum.Ldz,
            RMSa: singleSpectrum.RMSa,
            RMSv: singleSpectrum.RMSv,
            RMSd: singleSpectrum.RMSd,
            TmsBewertet: singleSpectrum.TmsBewertet,
            zsmillis: singleSpectrum.zsmillis
          };
          spectrums.push(spectrum);
        });
        console.log('spectrums out', spectrums);
        this.spectrumStore.dispatch({type: LOAD, data: spectrums});
      })).subscribe();
    return this.spectrums$;
  }
  from8to32 (src) {
    const dst = new ArrayBuffer(src.byteLength);
    new Uint8Array(dst).set(new Uint8Array(src));
    const view = new DataView(dst);
    const float32 = new Float32Array(Math.floor(src.byteLength / 4));
    for(let i = 0; i < float32.length; i++) {
      float32[i] = view.getFloat32(i * 4);
    }
    return float32;
  }
}
