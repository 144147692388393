import { LogkanalService } from './_services/logkanal.service';
import { Component, ElementRef } from '@angular/core';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  constructor( private logKanalService: LogkanalService, private elementRef:ElementRef) {
    const lkid = +this.elementRef.nativeElement.getAttribute('lkid'); // casts to number
    console.log("lkid", lkid)
    logKanalService.setLogischerKanal(lkid);
  }
}
