import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LogkanalService {
  logischerKanal = 0;
  constructor() { }
  getLogischerKanal() {
    return this.logischerKanal;
  }
  setLogischerKanal(kanalId) {
    this.logischerKanal = kanalId;
  }
}
